import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fc8f9fda = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _23d59db9 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _122d68b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _12b88584 = () => interopDefault(import('../pages/member.vue' /* webpackChunkName: "pages/member" */))
const _4e15570e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _68ebcb55 = () => interopDefault(import('../pages/realtime.vue' /* webpackChunkName: "pages/realtime" */))
const _4a308ad6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _660d81bb = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _5642a378 = () => interopDefault(import('../pages/topic/index.vue' /* webpackChunkName: "pages/topic/index" */))
const _6cf96ad7 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _6c943bc4 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _058af9b2 = () => interopDefault(import('../pages/brands/meridank.vue' /* webpackChunkName: "pages/brands/meridank" */))
const _1967efcf = () => interopDefault(import('../pages/brands/sinyi.vue' /* webpackChunkName: "pages/brands/sinyi" */))
const _ab736048 = () => interopDefault(import('../pages/amp/article/_id.vue' /* webpackChunkName: "pages/amp/article/_id" */))
const _5bddc927 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _45ecc53f = () => interopDefault(import('../pages/search/_q.vue' /* webpackChunkName: "pages/search/_q" */))
const _e72a7b36 = () => interopDefault(import('../pages/series-sort/_id.vue' /* webpackChunkName: "pages/series-sort/_id" */))
const _7e9323e0 = () => interopDefault(import('../pages/topic/_id.vue' /* webpackChunkName: "pages/topic/_id" */))
const _54c65f2c = () => interopDefault(import('../pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _3dbb4ad4 = () => interopDefault(import('../pages/author/_role/_name.vue' /* webpackChunkName: "pages/author/_role/_name" */))
const _15457de2 = () => interopDefault(import('../pages/category/_mainCategory/_subCategory.vue' /* webpackChunkName: "pages/category/_mainCategory/_subCategory" */))
const _bd1fea50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _fc8f9fda,
    meta: {},
    alias: ["/amp/about"],
    name: "about"
  }, {
    path: "/brands",
    component: _23d59db9,
    meta: {},
    alias: ["/amp/brands"],
    name: "brands"
  }, {
    path: "/contact",
    component: _122d68b4,
    meta: {},
    alias: ["/amp/contact"],
    name: "contact"
  }, {
    path: "/member",
    component: _12b88584,
    meta: {},
    alias: ["/amp/member"],
    name: "member"
  }, {
    path: "/privacy",
    component: _4e15570e,
    meta: {},
    alias: ["/amp/privacy"],
    name: "privacy"
  }, {
    path: "/category/%E6%9C%80%E6%96%B0",
    component: _68ebcb55,
    meta: {},
    alias: ["/amp/category/%E6%9C%80%E6%96%B0"],
    name: "realtime"
  }, {
    path: "/search",
    component: _4a308ad6,
    meta: {},
    alias: ["/amp/search"],
    name: "search"
  }, {
    path: "/sitemap",
    component: _660d81bb,
    meta: {},
    alias: ["/amp/sitemap"],
    name: "sitemap"
  }, {
    path: "/topic",
    component: _5642a378,
    meta: {},
    alias: ["/amp/topic"],
    name: "topic"
  }, {
    path: "/unsubscribe",
    component: _6cf96ad7,
    meta: {},
    alias: ["/amp/unsubscribe"],
    name: "unsubscribe"
  }, {
    path: "/video",
    component: _6c943bc4,
    meta: {},
    alias: ["/amp/video"],
    name: "video"
  }, {
    path: "/brands/meridank",
    component: _058af9b2,
    meta: {},
    alias: ["/amp/brands/meridank"],
    name: "brands-meridank"
  }, {
    path: "/brands/sinyi",
    component: _1967efcf,
    meta: {},
    alias: ["/amp/brands/sinyi"],
    name: "brands-sinyi"
  }, {
    path: "/amp/article/:id?",
    component: _ab736048,
    meta: {"amp":true},
    alias: [],
    name: "amp-article-id"
  }, {
    path: "/article/:id?",
    component: _5bddc927,
    meta: {},
    alias: ["/amp/article/:id?"],
    name: "article-id"
  }, {
    path: "/search/:q",
    component: _45ecc53f,
    meta: {},
    alias: ["/amp/search/:q"],
    name: "search-q"
  }, {
    path: "/series-sort/:id?",
    component: _e72a7b36,
    meta: {},
    alias: ["/amp/series-sort/:id?"],
    name: "series-sort-id"
  }, {
    path: "/topic/:id",
    component: _7e9323e0,
    meta: {},
    alias: ["/amp/topic/:id"],
    name: "topic-id"
  }, {
    path: "/video/:id",
    component: _54c65f2c,
    meta: {},
    alias: ["/amp/video/:id"],
    name: "video-id"
  }, {
    path: "/author/:role?/:name?",
    component: _3dbb4ad4,
    meta: {},
    alias: ["/amp/author/:role?/:name?"],
    name: "author-role-name"
  }, {
    path: "/category/:mainCategory?/:subCategory?",
    component: _15457de2,
    meta: {},
    alias: ["/amp/category/:mainCategory?/:subCategory?"],
    name: "category-mainCategory-subCategory"
  }, {
    path: "/",
    component: _bd1fea50,
    meta: {},
    alias: ["/amp/"],
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
